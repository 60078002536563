import React, { Component } from "react";
import BarraLateral from "../../../componentes/BarraLateral";
import InputPadrao from "../../../componentes/Inputs/ImputPadrao";
import BotaoPadrao from "../../../componentes/Botoes/BotaoPadrao";
import InputDataPadrao from "../../../componentes/Inputs/InputDataPadrao";
import ImputNumeroPadrao from "../../../componentes/Inputs/ImputNumeroPadrao";
import InputSelectPadrao from "../../../componentes/Inputs/InputSelectPadrao";
//import TooglePadrao from "../../../componentes/Botoes/TooglePadrao";
import TituloPagina from "../../../componentes/TituloPagina";
import TituloCadastroDetalhe from "../../../componentes/Titulos/TituloCadatrosDetalhe";
import MsgPopUpPadrao from "../../../componentes/PopUpModal/MsgPopUpPadrao";
//import TabelaPadrao from "../../../componentes/Tabelas/TabelaPadrao";
import ComboBoxPadrao from "../../../componentes/ComboBox/ComboboxPadrão"
import Rodape from "../../../componentes/Rodape";
import { Global_Converte_Data, Global_Valida_Data, Global_Retorna_Permissoes } from "../../../Funcoes_Globais";
import withRouter from "../../../Rotas/rotasParametros";
import Api from '../../../servicos/Axios';
import Estilos from "./estilo.module.css";
import PopUpLoadPadrao from '../../../componentes/PopUpLoad/PopUpLoadPadrao';
//import Select from 'react-select';

//https://www.freecodecamp.org/news/jwt-authentication-in-flask/

class CoraCobrancaCadastro extends Component{    
    constructor(props){
        super(props)

        this.state = {
            State_MsgPopUpPadrao_Visivel: false,
            State_MsgPopUpPadrao_Mensagem: '',
            State_MsgPopUpPadrao_Tipo: 1,
            State_MsgPopUpPadrao_Acao: '',
            State_MsgPopUpPadrao_Mostrar_Botoes: true,

            State_LoadPopUpPadrao_Visivel: false,
            State_LoadPopUpPadrao_Mensagem: '',
            
            State_Permissao_Editar: Global_Retorna_Permissoes('config_perm', 'editar'),
            State_Permissao_Ver: Global_Retorna_Permissoes('config_perm', 'ver'),
            State_Permissao_cadastrar: Global_Retorna_Permissoes('config_perm', 'cadastrar'),                        

            state_status_operacao: this.props.match.paramsLocation.state.status_operacao ,
            state_boleto_id: this.props.match.paramsLocation.state.boleto_id,  
            state_boleto_razao: '',
            state_boleto_fk_empresa: null,
            state_boleto_fk_revenda: null,
            state_boleto_valor: '',
            state_boleto_dt_vencimento: null,
            state_boleto_dt_cadastro: null,
            state_boleto_sincronizado: 'N',
            state_boleto_servico_nome: null,
            state_boleto_servico_descricao: null,
            state_boleto_bank_slip_url: null,
            state_boleto_tipo: null,
            state_boleto_gerado_api_interna: null,
            state_boleto_pagamento_manual: 'N',


            State_Pesq_Cliente: '',             
            State_Pesq_Lista_Clientes: [
                //{ label: 'Shark', value: 'Shark' },
                //{ label: 'Dolphin', value: 'Dolphin' },
                //{ label: 'Whale', value: 'Whale' },
                //{ label: 'Octopus', value: 'Octopus' },
                //{ label: 'Crab', value: 'Crab' },
                //{ label: 'Lobster', value: 'Lobster' },
            ],            
            State_Pesq_Cliente_Label_Padrao: undefined,
            State_Pesq_Cliente_value_Padrao: undefined,

            State_Paginacao_Primeira_Consulta: false,
            State_Paginacao_total: 0,
            State_Paginacao_Limit: 50,
            State_Paginacao_Offset: 0,
            
            state_lista_tipo_boleto: [
                {codigo: 1, descricao: 'Normal'},
                {codigo: 2, descricao: 'Mensalidade'}
            ],
        };
        
        this.Escolhe_Tipo_Boleto = this.Escolhe_Tipo_Boleto.bind(this);
        
        //https://stackoverflow.com/questions/70143135/how-to-use-react-router-dom-v6-navigate-in-class-component
        //O Match pega o parâmetro passado de outra tela pelo link, o navegar navega entre as telas
        //console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>");
        //console.log(this.props.match)         
        //console.log(this.props.match.paramsLocation.state.boleto_id);      
        //console.log(this.props.match.paramsLocation.state.status_operacao);
        //console.log(this.state.state_cliente_id)

    };

    componentDidMount (){
        this.Funcoes_Iniciais()                  
    };

    Funcoes_Iniciais = async () =>{
        if (this.state.state_status_operacao === 'editar' || this.state.state_status_operacao === 'ver') {
            this.Pesquisar_Boleto();
        }
    }    

    Abre_MsgPopUpPadrao(Tipo = 1, Mostrar_botoes = true, Mensagem = '', Acao_Botao = '', Tempo_fechar = 0){
        this.setState({
            State_MsgPopUpPadrao_Visivel: true,
            State_MsgPopUpPadrao_Tipo: Tipo,
            State_MsgPopUpPadrao_Mensagem: Mensagem,
            State_MsgPopUpPadrao_Acao: Acao_Botao, 
            State_MsgPopUpPadrao_Mostrar_Botoes: Mostrar_botoes,  
        }, () => {
            if (Tempo_fechar > 0){
                setTimeout(
                    this.Fecha_MsgPopUpPadrao.bind(this, 'timer'),
                    Tempo_fechar
                );                
            };
        });
    };

    Fecha_MsgPopUpPadrao(Tipo_botao){
        this.setState({
            State_MsgPopUpPadrao_Visivel: false,               
        }, () => {
            if ((Tipo_botao === 'ok') || (Tipo_botao === 'sim') || (Tipo_botao === 'timer')){
                if (this.state.State_MsgPopUpPadrao_Acao === 'Marcar_Como_Pago'){
                    this.Marcar_Como_Pago();
                };
            };
        });
    }; 
    
    Abre_LoadPopUpPadrao(mensagem){
        this.setState({
            State_LoadPopUpPadrao_Visivel: true,
            State_LoadPopUpPadrao_Mensagem: mensagem,            
        });
    };

    Fecha_LoadPopUpPadrao(){
        this.setState({
            State_LoadPopUpPadrao_Visivel: false,
            State_LoadPopUpPadrao_Mensagem: '',            
        });
    };     

    Atualiza_Dt_Vencimento(data_str){
        if (Global_Valida_Data(data_str)){
            this.setState({state_boleto_dt_vencimento: data_str});
        }
    }; 

    Pesquisar_Boleto(){
        var objJson = { 
            Pesq_Codigo: this.state.state_boleto_id,
        };
        
        this.Abre_LoadPopUpPadrao('Por favor aguarde ...');
        
        Api.Executa_chamada(
            this.props.navegar, 'Cora_Integracao_Detalhe_Boleto', 0, JSON.stringify(objJson)
        ).then((resposta) => {

            this.Fecha_LoadPopUpPadrao();
            if (resposta.status === 1){
                //console.log(resposta.dados.boleto)
                this.setState({                   
                    state_boleto_id: resposta.dados.boleto.codigo,
                    state_boleto_fk_empresa: resposta.dados.boleto.fk_empresa,
                    state_boleto_fk_revenda: resposta.dados.boleto.fk_revenda,
                    state_boleto_razao: resposta.dados.boleto.razao,
                    state_boleto_valor: resposta.dados.boleto.valor,
                    state_boleto_dt_cadastro: resposta.dados.boleto.dt_cadastro,
                    state_boleto_dt_vencimento: Global_Converte_Data(resposta.dados.boleto.dt_vencimento),
                    state_boleto_sincronizado: resposta.dados.boleto.sincronizado,
                    state_boleto_servico_nome: resposta.dados.boleto.nome_servico,
                    state_boleto_servico_descricao: resposta.dados.boleto.descricao_servico,
                    state_boleto_bank_slip_url: resposta.dados.boleto.bank_slip_url,
                    state_boleto_tipo: resposta.dados.boleto.tipo,
                    state_boleto_gerado_api_interna: resposta.dados.boleto.gerado_api_interna,
                    state_boleto_pagamento_manual: resposta.dados.boleto.pagamento_manual,
                }, function (){
                    if (this.state.state_status_operacao !== 'cadastrar'){
                        this.setState({
                            State_Pesq_Cliente_Label_Padrao: this.state.state_boleto_razao,
                            State_Pesq_Cliente_Value_Padrao: this.state.state_boleto_fk_empresa,
                        })
                    }
                });
            }else if (resposta.status === 2){
                this.Abre_MsgPopUpPadrao(3, false, resposta.msg, '', 2000)           
            }
            else{
                this.Abre_MsgPopUpPadrao(5, false, resposta.msg, '', 5000)
            };           
        });        
    };

    Salva_Boleto(){
        if (!this.state.state_boleto_tipo || this.state.state_boleto_tipo === 0){
            this.Abre_MsgPopUpPadrao(5, false, 'Tipo de Boleto Inválido!', '', 1500);
            return
        }        
        if (!Global_Valida_Data(this.state.state_boleto_dt_vencimento)) {
            this.Abre_MsgPopUpPadrao(5, false, 'Data de Vencimento Inválida!', '', 1500)
            return;            
        };

        if (!this.state.state_boleto_fk_empresa) {
            this.Abre_MsgPopUpPadrao(5, false, 'Informe o cliente!', '', 1500)
            return;            
        };

        if ((!this.state.state_boleto_servico_nome) || (this.state.state_boleto_servico_nome.trim() === '')) {
            this.Abre_MsgPopUpPadrao(5, false, 'Nome do Serviço inválido!', '', 1500)
            return;            
        };            

        if ((!this.state.state_boleto_servico_descricao) || (this.state.state_boleto_servico_descricao.trim() === '')) {
            this.Abre_MsgPopUpPadrao(5, false, 'Descrição do Serviço inválida!', '', 1500)
            return;            
        };          
        
        if (this.state.state_boleto_valor <= 0) {
            this.Abre_MsgPopUpPadrao(5, false, 'Valor Inválido!', '', 1500)
            return;            
        };                 

        //console.log(
        //    this.state.state_boleto_id,
        //    this.state.state_boleto_fk_empresa,
        //    this.state.state_boleto_razao,
        //    this.state.state_boleto_valor,
        //    this.state.state_boleto_dt_vencimento,
        //)
        

        var objJson = {
            api_operacao: this.state.state_status_operacao, 
            boleto_id: this.state.state_boleto_id,
            boleto_fk_empresa:  this.state.state_boleto_fk_empresa,
            boleto_razao: this.state.state_boleto_razao,
            boleto_valor: this.state.state_boleto_valor,
            boleto_dt_vencimento: this.state.state_boleto_dt_vencimento,
            boleto_servico_nome: this.state.state_boleto_servico_nome,
            boleto_servico_descricao: this.state.state_boleto_servico_descricao,
            boleto_tipo: this.state.state_boleto_tipo,
        };        

        this.Abre_LoadPopUpPadrao('Salvando informações  ...');
        Api.Executa_chamada(
            this.props.navegar, 'Cora_Integracao_Atu_Cadastra_Boleto', 0, JSON.stringify(objJson)
        ).then((resposta) => {
            this.Fecha_LoadPopUpPadrao();
            if (resposta.status === 1){
                //console.log(resposta.dados)
                if (this.state.state_status_operacao === 'cadastrar'){
                    this.setState({
                        state_boleto_id: resposta.dados.boleto_id,
                        state_status_operacao: 'editar',
                    }, ()=>{
                        this.Pesquisar_Boleto();
                        this.Abre_MsgPopUpPadrao(1, false, 'Boleto cadastrado com sucesso!', '', 2000);
                    });
                }else{
                    this.Abre_MsgPopUpPadrao(1, false, 'Cadastro atualizado com sucesso!', '', 2000);
                }
            }else if (resposta.status === 2){
                this.Abre_MsgPopUpPadrao(3, false, resposta.msg, '', 2000)           
            }
            else{
                this.Abre_MsgPopUpPadrao(5, false, resposta.msg, '', 5000)
            };           
        });        
    }

    Gerar_Boleto(){
        //if (!Global_Valida_Data(this.state.state_boleto_dt_vencimento)) {
        //    this.Abre_MsgPopUpPadrao(5, false, 'Data de Vencimento Inválida!', '', 1500)
        //    return;            
        //};      

        
        //console.log(
        //    this.state.state_boleto_id,
        //    this.state.state_boleto_fk_empresa,
        //    this.state.state_boleto_razao,
        //    this.state.state_boleto_valor,
        //    this.state.state_boleto_dt_vencimento,
        //)
        

        var objJson = {
            boleto_id: this.state.state_boleto_id,
        };        

        this.Abre_LoadPopUpPadrao('Salvando informações  ...');
        Api.Executa_chamada(
            this.props.navegar, 'Cora_Integracao_Gera_Boleto', 10000, JSON.stringify(objJson)
        ).then((resposta) => {
            this.Fecha_LoadPopUpPadrao();
            if (resposta.status === 1){
                this.Pesquisar_Boleto();
                this.Abre_MsgPopUpPadrao(1, false, 'Boleto Gerado com Sucesso!', '', 2000);
            }else if (resposta.status === 2){
                this.Abre_MsgPopUpPadrao(3, true, resposta.msg, '', 0)           
            }
            else{
                this.Abre_MsgPopUpPadrao(5, false, resposta.msg, '', 5000)
            };           
        });        
    } 
    
    Baixar_Boleto(event){
        event.preventDefault(); // Stop the default navigation
        if (this.state.state_boleto_bank_slip_url){
            window.open(this.state.state_boleto_bank_slip_url, '_blank', 'noopener,noreferrer');
            this.Abre_MsgPopUpPadrao(1, false, 'Download iniciado!', '', 2000);
        }else{
            this.Abre_MsgPopUpPadrao(3, false, 'O link para Download não está disponível!', '', 2000);
        }
    }

    Pergunta_Marcar_Como_Pago(){
        this.Abre_MsgPopUpPadrao(2, true, 'Tem certeza que gostaria de registrar este boleto como pago?', 'Marcar_Como_Pago', 0)
    };

    Marcar_Como_Pago(){
        var objJson = {
            boleto_id: this.state.state_boleto_id,
        };        

        this.Abre_LoadPopUpPadrao('Salvando informações  ...');
        Api.Executa_chamada(
            this.props.navegar, 'Cora_Integracao_Marcar_Pago_Boleto', 0, JSON.stringify(objJson)
        ).then((resposta) => {
            this.Fecha_LoadPopUpPadrao();
            if (resposta.status === 1){
                this.Pesquisar_Boleto();
                this.Abre_MsgPopUpPadrao(1, false, 'Boleto atualizado como pago!', '', 2000);
            }else if (resposta.status === 2){
                this.Abre_MsgPopUpPadrao(3, true, resposta.msg, '', 0)           
            }
            else{
                this.Abre_MsgPopUpPadrao(5, false, resposta.msg, '', 5000)
            };           
        });        
    } 

    Pesquisar_Cliente(Offset = 0){
        /*
            O State Pesq_Pagi_Primeira_Consulta Precisa ser alterado no onchange do imput da consulta
            Se o retorno for  dois, jogar o state State_Paginacao_total: 9, State_Paginacao_Limit: 9, State_Paginacao_Offset: 0
        */
        this.setState({
            State_Paginacao_Offset: Offset,
            State_lista_clientes: []
        });

        var objJson = { 
            Pesq_Valor: this.state.State_Pesq_Cliente,
            Pesq_Pagi_Offset: Offset,
            Pesq_Pagi_Limit: this.state.State_Paginacao_Limit,
            Pesq_Pagi_Primeira_Consulta: this.state.State_Paginacao_Primeira_Consulta, 
        }

        this.Abre_LoadPopUpPadrao('Por favor aguarde ...');
        Api.Executa_chamada(
            this.props.navegar, 'Cliente_Consulta', 0, JSON.stringify(objJson), this.props.navegar
        ).then((resposta) => {
            this.Fecha_LoadPopUpPadrao();
            if (resposta.status === 1){
                const itens_api = Object.entries(resposta.dados.cliente).map( ( item ) => ({
                    label: `${item[1].descricao} | ${item[1].cnpj}`, value:item[1].codigo,
                    razao: item[1].descricao,
                }));
                this.setState({
                    State_Pesq_Lista_Clientes: itens_api
                }, function(){
                    //console.log('Total>', this.state.State_Paginacao_total);
                });
            }else if (resposta.status === 2){
                this.Abre_MsgPopUpPadrao(3, false, resposta.msg, '', 2000)                         
            }
            else{
                this.Abre_MsgPopUpPadrao(5, false, resposta.msg, '', 5000)
            };           
        });
    }

    Escolher_Cliente(codigo){
        var json = this.state.State_Pesq_Lista_Clientes.find(element => element.value === codigo)
        this.setState({
            state_boleto_fk_empresa: json.value,
            state_boleto_razao: json.razao,
        })
    }

    Escolhe_Tipo_Boleto(e){
        e.preventDefault();
        this.setState({
            state_boleto_tipo: parseInt(e.target.value),
        });
    }     
    
    render(){
        return(
        <div>
            <MsgPopUpPadrao
                visivel = {this.state.State_MsgPopUpPadrao_Visivel}
                mensagem = {this.state.State_MsgPopUpPadrao_Mensagem}
                tipo = {this.state.State_MsgPopUpPadrao_Tipo}
                mostrar_botoes = {this.state.State_MsgPopUpPadrao_Mostrar_Botoes}
                onclick_btn_ok = {this.Fecha_MsgPopUpPadrao.bind(this, 'ok')}
                onclick_btn_sim = {this.Fecha_MsgPopUpPadrao.bind(this, 'sim')}
                onclick_btn_nao = {this.Fecha_MsgPopUpPadrao.bind(this, 'nao')}
            />
            <PopUpLoadPadrao
                visivel = {this.state.State_LoadPopUpPadrao_Visivel}
                mensagem = {this.state.State_LoadPopUpPadrao_Mensagem}
            />                                 
            <BarraLateral/>
            <div className="GlobalConteinerPrincipal"> 
                <div className="GlobalConteudoPrincipal">

                    <TituloPagina titulo={"Cora > Cadastro"} />
                    <TituloCadastroDetalhe titulo={"Geral"} />                                    
                    <div className={Estilos.ContainerGrid}>
                        <InputSelectPadrao
                            titulo = {'*Cliente'}
                            options={this.state.State_Pesq_Lista_Clientes}
                            onInputChange={valor => {this.setState({State_Pesq_Cliente: valor})}}
                            onKeyDown={
                                event => {
                                    //console.log('Key', event.key)
                                    if (event.key === 'Enter' || event.key === 'ArrowDown' || event.key === 'ArrowUp') {
                                         if (event.key === 'Enter' && this.state.State_Pesq_Lista_Clientes.length <= 0){
                                            this.Pesquisar_Cliente()
                                         }
                                    }else{
                                        this.setState({State_Pesq_Lista_Clientes:[]})
                                    } 
                                }
                            }
                            value_label = {this.state.state_boleto_razao}
                            value_value = {this.state.state_boleto_fk_empresa}
                            onChange={valor => {this.Escolher_Cliente(valor.value) }}
                            placeholder = {'Pesquisar Cliente'}
                            isDisabled = {(this.state.state_boleto_sincronizado === 'S' || this.state.state_boleto_gerado_api_interna === 'S' || this.state.state_boleto_pagamento_manual === 'S') ? true : false}
                        />
                        {
                            /*
                            <Select 
                                options={this.state.State_Pesq_Lista_Clientes}
                                onInputChange={valor => {this.setState({State_Pesq_Cliente: valor})}}
                                onKeyDown={
                                    event => {
                                        //console.log('Key', event.key)
                                        if (event.key === 'Enter' || event.key === 'ArrowDown' || event.key === 'ArrowUp') {
                                            if (event.key === 'Enter' && this.state.State_Pesq_Lista_Clientes.length <= 0){
                                                this.Pesquisar_Cliente()
                                            }
                                        }else{
                                            this.setState({State_Pesq_Lista_Clientes:[]})
                                        } 
                                    }
                                }
                                value={{ label: this.state.state_boleto_razao, value: this.state.state_boleto_fk_empresa}}
                                //defaultValue={{ label: 'TEste', value: '0' }}
                                onChange={valor => {this.Escolher_Cliente(valor.value) }}
                                placeholder = {'Pesquisar Cliente'}
                                noOptionsMessage={({inputValue}) => !inputValue && "Nenhuma Informação Disponível"}
                                styles={this.state.State_customStyles} 
                                filterOption={() => true} //Desabilita o filtro para que apareça a lista sem que o valor pesquisado esteja no label
                                //https://medium.com/@walidkabou/implementing-and-customizing-react-select-a-comprehensive-guide-92d696ea5c35                         
                            />                             
                            */
                        } 
                        <ComboBoxPadrao
                            titulo = {'*Tipo de Boleto'}
                            dica = {'Nenhum'}
                            itens = {this.state.state_lista_tipo_boleto}
                            onChange = {this.Escolhe_Tipo_Boleto}
                            select_value = {this.state.state_boleto_tipo}
                            disabled = {(this.state.state_boleto_sincronizado === 'S' || this.state.state_boleto_gerado_api_interna === 'S' || this.state.state_boleto_pagamento_manual === 'S') ? true : false}
                        />                                                 
                        <InputDataPadrao 
                            titulo = '*Vencimento'
                            value={this.state.state_boleto_dt_vencimento}
                            onChange={valor => this.Atualiza_Dt_Vencimento(valor.target.value)}    
                            disabled = {(this.state.state_boleto_sincronizado === 'S' || this.state.state_boleto_pagamento_manual === 'S') ? true : false}
                        />                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          
                    </div>
                    <TituloCadastroDetalhe titulo={"Serviço"} />                 
                    <div className={Estilos.ContainerGrid}>
                        <InputPadrao 
                                titulo={'Nome'}
                                onChange = {valor => this.setState({state_boleto_servico_nome: valor.target.value})}
                                value = {this.state.state_boleto_servico_nome}   
                                disabled = {(this.state.state_boleto_sincronizado === 'S' || this.state.state_boleto_pagamento_manual === 'S') ? true : false}
                                maxLength = {50} 
                        /> 
                        <InputPadrao 
                                titulo={'Descrição'}
                                onChange = {valor => this.setState({state_boleto_servico_descricao: valor.target.value})}
                                value = {this.state.state_boleto_servico_descricao}   
                                disabled = {(this.state.state_boleto_sincronizado === 'S'  || this.state.state_boleto_pagamento_manual === 'S') ? true : false}
                                maxLength = {200} 
                        />
                        <ImputNumeroPadrao
                            titulo = 'Valor'
                            value={this.state.state_boleto_valor}
                            onValueChange = {valor => this.setState({state_boleto_valor: valor.value})}
                            disabled = {(this.state.state_boleto_sincronizado === 'S'  || this.state.state_boleto_pagamento_manual === 'S') ? true : false}
                        />                                                   
                    </div>                                                                        
               
                    <div className={Estilos.ConteinerBotoes} >
                        <BotaoPadrao 
                            tipo={"voltar"} 
                            descricao={"Voltar"} 
                            onClick={() =>{this.props.navegar(-1)}} 
                        />
                        {
                            //((this.state.State_Permissao_Editar || this.state.State_Permissao_cadastrar) && (this.state.state_status_operacao === 'editar' || this.state.state_status_operacao === 'cadastrar') && (this.state_boleto_sincronizado === 'N')) ?
                            ((this.state.State_Permissao_Editar || this.state.State_Permissao_cadastrar) 
                            && (this.state.state_status_operacao === 'editar' || this.state.state_status_operacao === 'cadastrar') 
                            && (this.state.state_boleto_sincronizado === 'N' && this.state.state_boleto_pagamento_manual === 'N')) ?
                            <BotaoPadrao
                                tipo={"salvar"} 
                                descricao={"Salvar"} 
                                onClick={() =>{this.Salva_Boleto()}} 
                            />
                            : null
                        }
                            
                        {
                            (this.state.state_boleto_id > 0) && (this.state.state_boleto_sincronizado === 'N') && (this.state.state_boleto_pagamento_manual === 'N') ?
                            <BotaoPadrao 
                                tipo={"sincronizar"} 
                                descricao={"Gerar Boleto"} 
                                onClick={() =>{this.Gerar_Boleto()}} 
                            /> 
                            : null                                                           
                        }
                        {
                            (this.state.state_boleto_id > 0) && (this.state.state_boleto_pagamento_manual === 'N') && (this.state.state_boleto_sincronizado === 'N') ?
                            <BotaoPadrao 
                                tipo={""} 
                                descricao={"Marcar como pago"} 
                                onClick={() =>{this.Pergunta_Marcar_Como_Pago()}} 
                            /> 
                            : null                                                           
                        }                          
                        {
                            (this.state.state_boleto_sincronizado === 'S') ?
                            <BotaoPadrao 
                                //tipo={"sincronizar"} 
                                descricao={"Baixar Boleto"} 
                                onClick={(event) =>{
                                    //this.Gerar_Boleto();
                                    this.Baixar_Boleto(event);
                                }} 
                            />
                            : null
                        }                                      
                    </div>                                                                                                   
                </div>
            </div>
            <Rodape/>
        </div>            
        );
    };
};

export default withRouter(CoraCobrancaCadastro);
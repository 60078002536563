import React, { Component } from "react";
import EstiloPadrao from "./estilo.module.css";
//import { FaSearch } from "react-icons/fa";
//import { CoresGlobal } from "../../../../estilos";

class ImputModelo extends Component {
    constructor(props){
        super(props)

        //Este state é necessário para o getDerivedStateFromProps
        this.state ={
            state_titulo: '',
            state_dica: '',
            state_estilo: '',
            state_onChange: '' ,
            state_onKeyUp: undefined,
            state_tipo: undefined,
            state_value: '',
            state_disabled: '',
            state_maxLength: undefined
        }
    }

    static getDerivedStateFromProps(props, state) {
        return {
            state_titulo: props.titulo,
            state_dica: props.dica,
            state_estilo: props.estilo,
            state_onChange: props.onChange,
            state_onClick: props.onClick,
            state_value: props.value,
            state_disabled: props.disabled,
            state_onKeyUp: props.onKeyUp,                  
            state_tipo: props.tipo,
            state_maxLength: props.maxLength
        }
    } 

    render(){
        return(
            <div style={{display: 'flex', width: '100%'}}>
                <input
                    disabled = {this.state.state_disabled}        
                    className={EstiloPadrao.inputPadrao} 
                    type="text"
                    placeholder={this.state.state_dica}
                    onChange={this.state.state_onChange} 
                    value={this.state.state_value}
                    onKeyUp={this.props.onKeyUp}
                    autoFocus = {this.props.autoFocus}
                    maxLength={this.props.maxLength}
                >   
                </input>                                                
            </div>
        );
    }
};

export default ImputModelo;
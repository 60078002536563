import { FaWhatsapp , FaPlus, FaSearch, FaSave, FaBackward, FaEdit, FaEye, FaSyncAlt, } from "react-icons/fa";
import { RiDeleteBin2Fill } from "react-icons/ri";
import { MdAttachMoney } from "react-icons/md";
import { CoresGlobal } from "../../../estilos"

import Estilo from "./estilo.module.css";
import React from "react";

const BotaoPadrao = ({descricao, tipo, estilo, onClick}) =>{    
    const SetaCorFundoPadrao =  () => {
        var cor_fundo = CoresGlobal.cor_botao_padrao;
        if ((estilo) && (estilo["background-color"] !== undefined)){
            cor_fundo = estilo["background-color"];
        }else if(tipo === "salvar"){
            cor_fundo = CoresGlobal.cor_paleta_4;
        }else if(tipo === "excluir"){
            cor_fundo = CoresGlobal.cor_paleta_3;
        }else if(tipo === "editar"){
            cor_fundo = CoresGlobal.cor_paleta_1;
        }else if(tipo === "pesquisar"){
            cor_fundo = CoresGlobal.cor_paleta_1;
        }else if(tipo === "whatsapp"){
            cor_fundo = CoresGlobal.cor_paleta_4;
        }else if(tipo === "cifrao"){
            cor_fundo = CoresGlobal.cor_paleta_9;
        }                 
        return(cor_fundo);   
    };    
     
    return(
        <div >
            <button 
                //Aqui seta a cor padrão, mas se o estilo tiver valores, vai usar ele
                style={Object.assign({}, {"background-color": SetaCorFundoPadrao()}, estilo)}
                className={Estilo.Botao}
                onClick={onClick}
            >
                <div className={Estilo.Alinha}>
                    {
                        (tipo === "adicionar" ? <FaPlus size={15} style={{marginRight: (!descricao) ? 0 : 4}}/> : null)
                    }   
                    {
                        (tipo === "pesquisar" ? <FaSearch size={15} style={{marginRight: (!descricao) ? 0 : 4}}/> : null)
                    }
                    {
                        (tipo === "voltar" ? <FaBackward size={15} style={{marginRight: (!descricao) ? 0 : 4}}/> : null)
                    }                   
                    {
                        (tipo === "salvar" ? <FaSave size={15} style={{marginRight: (!descricao) ? 0 : 4}}/> : null)
                    }
                    {
                        (tipo === "editar" ? <FaEdit size={15} style={{marginRight: (!descricao) ? 0 : 4}}/> : null)
                    }
                    {
                        (tipo === "visualizar" ? <FaEye size={15} style={{marginRight: (!descricao) ? 0 : 4}}/> : null)
                    }
                    {
                        (tipo === "excluir" ? <RiDeleteBin2Fill size={15} style={{marginRight: (!descricao) ? 0 : 4}}/> : null)
                    } 
                    {
                        (tipo === "sincronizar" ? <FaSyncAlt size={15} style={{marginRight: (!descricao) ? 0 : 4}}/> : null)
                    }
                    {
                        (tipo === "whatsapp" ? <FaWhatsapp size={15} style={{marginRight: (!descricao) ? 0 : 4}}/> : null)
                    }
                    {
                        (tipo === "cifrao" ? <MdAttachMoney size={15} style={{marginRight: (!descricao) ? 0 : 4}}/> : null)
                    }                                                                                                                                                 
                    {
                        (descricao ? descricao : null)
                    }             
                </div>
            </button>
        </div> 
    );
};

export default BotaoPadrao;
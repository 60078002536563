import React, { Component } from "react";
import EstiloPadrao from "./estilo.module.css";
import { FaArrowAltCircleDown , FaArrowAltCircleUp} from "react-icons/fa";
import { CoresGlobal } from "../../estilos";
//import ImputModelo from "./ImputModelo"
//import { NumericFormat } from 'react-number-format';

class ContainerFiltro extends Component {
    constructor(props){
        super(props)

        //Este state é necessário para o getDerivedStateFromProps
        this.state ={
            state_titulo: '',
            state_mostra_filtro: false,
            state_onClick: undefined,
        }
    }

    static getDerivedStateFromProps(props, state) {
        return {
            state_titulo: props.titulo,
            //state_mostra_filtro: props.mostra_filtro,            
            state_onClick: props.onClick, 
        }
    } 

    Mostra_Filtro (){
        this.setState({
            state_mostra_filtro: !this.state.state_mostra_filtro
        })
    }

    render(){
        return(
            <div style={{'--cor': CoresGlobal.cor_paleta_1}} className={EstiloPadrao.Container}>
                <button
                    className={EstiloPadrao.Botao}
                    onClick={() =>{this.Mostra_Filtro()}}
                >
                    <div className={EstiloPadrao.Alinha}>
                        {
                            this.state.state_mostra_filtro ?
                            <FaArrowAltCircleUp  size={15}/>
                            :
                            <FaArrowAltCircleDown size={15}/>
                        }
                        <p
                            style={{width: '100%'}}
                        >
                            Filtros
                        </p>                        
                    </div>
                </button>
                <div className={this.state.state_mostra_filtro ? EstiloPadrao.ContainerConteudo : EstiloPadrao.ContainerConteudoVazio}>
                    {
                        this.state.state_mostra_filtro ?
                        <div
                        //style={{backgroundColor: 'red'}}
                        >
                            {this.props.children}
                        </div>
                        : null
                    }
                </div>
            </div>
        );
    }
};

export default ContainerFiltro;
import React, { Component } from "react";
import EstiloPadrao from "./estilo.module.css";
import { FaSearch } from "react-icons/fa";
import { CoresGlobal } from "../../../estilos";
import ImputModelo from "./ImputModelo"
import { PatternFormat } from "react-number-format";

class ImputNumeroPadrao extends Component {
    constructor(props){
        super(props)

        //Este state é necessário para o getDerivedStateFromProps
        this.state ={
            state_titulo: '',
            state_tipo: '',
            state_onValueChange: '',
            state_value: '',            

            state_dica: '',
            state_estilo: '',
            state_disabled: '',
            state_maxLength: undefined,
            state_prefix: '',
        }
    }

    static getDerivedStateFromProps(props, state) {
        return {
            state_titulo: props.titulo,
            state_tipo: props.tipo,
            state_onValueChange: props.onValueChange,
            state_value: props.value,

            state_dica: props.dica,
            state_estilo: props.estilo,
            state_onClick: props.onClick,
            state_disabled: props.disabled,                
            state_maxLength: props.maxLength,
            state_prefix: props.prefix,
        }
    } 

    render(){
        return(
            <div
                className={EstiloPadrao.ContainerInput} 
                //style={{'width': '100%', background: CoresGlobal.cor_botao_padrao, border: `solid 0.1em ${CoresGlobal.cor_paleta_6}` }} 
                style={{'width': '100%', '--cor_paleta_1': CoresGlobal.cor_paleta_1}} 
            >
                <div className={EstiloPadrao.ContainerConteudo}>                  
                    {
                        (this.state.state_titulo !== '') ?
                        <div 
                            className={EstiloPadrao.tituloInput} 
                            //style={{backgroundColor: CoresGlobal.cor_botao_padrao}}
                        >
                            {this.state.state_titulo}
                        </div>
                        : null
                    }
                    <div style={{display: 'flex'}}>
                        {
                            this.state.state_tipo === 'pesquisa' ?
                            <div className={EstiloPadrao.Alinha_Icon} style={{padding: '0 .5em'}}>
                                <FaSearch 
                                    size={20} 
                                    color="white"
                                    onClick={this.state.state_onClick}
                                />
                            </div>
                            : null
                        }
                        <PatternFormat 
                            format={
                                this.state.state_tipo === 'celular' ?
                                "(##) # #### ####"
                                : this.state.state_tipo === 'cpf' ?
                                "###.###.###-##"
                                : this.state.state_tipo === 'cnpj' ?
                                "##.###.###/####-##"
                                : null
                            }
                            mask="_"
                            allowEmptyFormatting={true} //Funciona junto com o mask
                            onValueChange = {this.state.state_onValueChange}
                            value={
                                this.state.state_tipo === 'cpf' ?
                                this.state.state_value.substring(0, 11)
                                :this.state.state_tipo === 'cnpj' ? 
                                this.state.state_value.substring(0, 14)
                                :this.state.state_value
                            }
                            //onValueChange = {this.state.state_onValueChange}
                            //allowLeadingZeros = {false}
                            //allowNegative = {false}
                            //decimalScale={2}
                            //fixedDecimalScale = {true}
                            //decimalSeparator=","
                            //allowedDecimalSeparators={'.'}
                            //thousandSeparator = {"."}
                            //prefix={this.state.state_prefix}
                            customInput={ImputModelo}
                            //disabled = {this.state.state_disabled}
                        />                                             
                    </div>
                </div>
            </div>
        );
    }
};

export default ImputNumeroPadrao;
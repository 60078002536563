import React, { Component } from "react";
import EstiloPadrao from "./estilo.module.css";
//import { FaSearch } from "react-icons/fa";
import { CoresGlobal } from "../../../estilos";
import Select from 'react-select';

class ImputSelectMultParao extends Component {
    constructor(props){
        super(props)

        //Este state é necessário para o getDerivedStateFromProps
        this.state ={
            state_titulo: '',
            state_placeholder: '',
            state_value: [],
            state_options: [],
            state_onChange: undefined,            

            state_dica: '',
            state_estilo: '',
            state_isDisabled: false,

        }
    }

    static getDerivedStateFromProps(props, state) {
        return {
            state_titulo: props.titulo,
            state_placeholder: props.placeholder,
            state_value: props.value,
            state_options: props.options,
            state_onChange: props.onChange,            

            state_dica: props.dica,
            state_estilo: props.estilo,
            state_isDisabled: props.isDisabled
        }
    } 

    render(){
        return(
            <div
                className={EstiloPadrao.ContainerInput} 
                //style={{'width': '100%', background: CoresGlobal.cor_botao_padrao, border: `solid 0.1em ${CoresGlobal.cor_paleta_6}` }} 
                //style={Object.assign({}, {'--cor_paleta_7': CoresGlobal.cor_paleta_7, 'width': '100%', background: CoresGlobal.cor_botao_padrao, border: `solid 0.1em ${CoresGlobal.cor_paleta_6}` }, this.state.state_estilo)}
                style={Object.assign({}, {'--cor_paleta_1': CoresGlobal.cor_paleta_1, 'width': '100%' }, this.state.state_estilo)}
            >
                <div className={EstiloPadrao.ContainerConteudo}>
                {
                    (this.state.state_titulo !== '') ?
                    <div 
                        className={EstiloPadrao.tituloInput} 
                        //style={{backgroundColor: CoresGlobal.cor_botao_padrao}}
                    >
                        {this.state.state_titulo}
                    </div>
                    : null
                }                
                <Select 
                    className={EstiloPadrao.React_Select}
                    //styles={{'--cor_teste': CoresGlobal.cor_botao_padrao}}                    
                    placeholder={this.state.state_placeholder}
                    value={this.state.state_value}
                    options={this.state.state_options}
                    onChange={this.state.state_onChange}
                    isMulti = {true}
                    //menuIsOpen = {true}
                />
                </div>
            </div>
        );
    }
};

export default ImputSelectMultParao;
import React, { Component } from "react";
import BarraLateral from "../../../../componentes/BarraLateral";
import InputPadrao from "../../../../componentes/Inputs/ImputPadrao";
import BotaoPadrao from "../../../../componentes/Botoes/BotaoPadrao";
import ComboBoxPadrao from "../../../../componentes/ComboBox/ComboboxPadrão"
import TituloPagina from "../../../../componentes/TituloPagina";
import MsgPopUpPadrao from "../../../../componentes/PopUpModal/MsgPopUpPadrao";
//import InputDataPadrao from "../../../../componentes/Inputs/InputDataPadrao";
import TabelaTipo1 from "../../../../componentes/Tabelas/TabelaTipo1";
import TooglePadrao from "../../../../componentes/Botoes/TooglePadrao";
//import TituloComponentPadrao from "../../../../componentes/TituloComponentes/TituloPadrao";
import TituloCadastroDetalhe from "../../../../componentes/Titulos/TituloCadatrosDetalhe";
import Rodape from "../../../../componentes/Rodape";
import { Global_Valida_Data, Global_Retorna_Permissoes } from "../../../../Funcoes_Globais"
import withRouter from "../../../../Rotas/rotasParametros";
import Api from '../../../../servicos/Axios';
import Estilos from "./estilo.module.css";
import PopUpLoadPadrao from '../../../../componentes/PopUpLoad/PopUpLoadPadrao'

//https://www.freecodecamp.org/news/jwt-authentication-in-flask/

class ClientesCadastro extends Component{    
    constructor(props){
        super(props)

        this.state = {
            State_MsgPopUpPadrao_Visivel: false,
            State_MsgPopUpPadrao_Mensagem: '',
            State_MsgPopUpPadrao_Tipo: 1,
            State_MsgPopUpPadrao_Acao: '',
            State_MsgPopUpPadrao_Mostrar_Botoes: true,

            State_LoadPopUpPadrao_Visivel: false,
            State_LoadPopUpPadrao_Mensagem: '', 
            
            State_Permissao_Editar: Global_Retorna_Permissoes('config_perm', 'editar'),
            State_Permissao_Ver: Global_Retorna_Permissoes('config_perm', 'ver'),
            State_Permissao_cadastrar: Global_Retorna_Permissoes('config_perm', 'cadastrar'),            

            state_status_operacao: this.props.match.paramsLocation.state.status_operacao,
            state_permissao_id: this.props.match.paramsLocation.state.permissao_id,
            state_permissao_descricao: '',
            state_permissao_cod_revenda: 0,

            state_lista_revendas: [],

            state_lista_permissoes: [],
            state_permissao_cliente_ver: 'N',
            state_permissao_cliente_cadastrar: 'N',
            state_permissao_cliente_editar: 'N',
            state_permissao_cliente_excluir: 'N',
            state_permissao_usuario_ver: 'N',
            state_permissao_usuario_cadastrar: 'N',
            state_permissao_usuario_editar: 'N',
            state_permissao_usuario_excluir: 'N',  
            state_permissao_config_perm_ver: 'N',
            state_permissao_config_perm_cadastrar: 'N',
            state_permissao_config_perm_editar: 'N',
            state_permissao_config_perm_excluir: 'N',    
            state_permissao_revenda_ver: 'N',
            state_permissao_revenda_cadastrar: 'N',
            state_permissao_revenda_editar: 'N',
            state_permissao_revenda_excluir: 'N',                     
        };
        this.Escolhe_Revenda = this.Escolhe_Revenda.bind(this);
        this.Retorna_Listar_Diversas = this.Retorna_Listar_Diversas.bind(this);
        
        //https://stackoverflow.com/questions/70143135/how-to-use-react-router-dom-v6-navigate-in-class-component
        //O Match pega o parâmetro passado de outra tela pelo link, o navegar navega entre as telas
        //console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>");
        //console.log(this.props.match)         
        //console.log(this.props.match.paramsLocation.state.usuario_id);      
        //console.log(this.props.match.paramsLocation.state.status_operacao);
        //console.log(this.state.state_cliente_id)

    };

    componentDidMount (){
        this.Funcoes_Iniciais();
    };

    Funcoes_Iniciais = async () =>{
        //Precisa esperar o retorno da função Retorna_Listar_Diversas
        await this.Retorna_Listar_Diversas();
        if (this.state.state_status_operacao === 'editar' || this.state.state_status_operacao === 'ver') {
            this.Pesquisar_Permissao();
        }    
    }    

    Atualiza_Componentes_Permissao(){
        this.setState({
            state_permissao_cliente_ver: 'N',
            state_permissao_cliente_cadastrar: 'N',
            state_permissao_cliente_editar: 'N',
            state_permissao_cliente_excluir: 'N',
            state_permissao_usuario_ver: 'N',
            state_permissao_usuario_cadastrar: 'N',
            state_permissao_usuario_editar: 'N',
            state_permissao_usuario_excluir: 'N',  
            state_permissao_config_perm_ver: 'N',
            state_permissao_config_perm_cadastrar: 'N',
            state_permissao_config_perm_editar: 'N',
            state_permissao_config_perm_excluir: 'N',
            state_permissao_revenda_ver : 'N',
            state_permissao_revenda_cadastrar : 'N',
            state_permissao_revenda_editar: 'N',
            state_permissao_revenda_excluir: 'N',                        
        });

        this.state.state_lista_permissoes.forEach(item =>{
            if(item.rotina === 'cliente'){
                this.setState({
                    state_permissao_cliente_ver : item.ver,
                    state_permissao_cliente_cadastrar : item.cadastrar,
                    state_permissao_cliente_editar : item.editar,
                    state_permissao_cliente_excluir : item.excluir,
                });
            }else if(item.rotina === 'usuario'){
                this.setState({
                    state_permissao_usuario_ver : item.ver,
                    state_permissao_usuario_cadastrar : item.cadastrar,
                    state_permissao_usuario_editar: item.editar,
                    state_permissao_usuario_excluir: item.excluir,
                });
            }else if(item.rotina === 'config_perm'){
                this.setState({
                    state_permissao_config_perm_ver : item.ver,
                    state_permissao_config_perm_cadastrar : item.cadastrar,
                    state_permissao_config_perm_editar: item.editar,
                    state_permissao_config_perm_excluir: item.excluir,
                });
            }else if(item.rotina === 'revenda'){
                this.setState({
                    state_permissao_revenda_ver : item.ver,
                    state_permissao_revenda_cadastrar : item.cadastrar,
                    state_permissao_revenda_editar: item.editar,
                    state_permissao_revenda_excluir: item.excluir,
                });
            }
        });
    };

    Abre_MsgPopUpPadrao(Tipo = 1, Mostrar_botoes = true, Mensagem = '', Acao_Botao = '', Tempo_fechar = 0){
        this.setState({
            State_MsgPopUpPadrao_Visivel: true,
            State_MsgPopUpPadrao_Tipo: Tipo,
            State_MsgPopUpPadrao_Mensagem: Mensagem,
            State_MsgPopUpPadrao_Acao: Acao_Botao, 
            State_MsgPopUpPadrao_Mostrar_Botoes: Mostrar_botoes,  
        }, () => {
            if (Tempo_fechar > 0){
                setTimeout(
                    this.Fecha_MsgPopUpPadrao.bind(this, 'timer'),
                    Tempo_fechar
                );                
            };
        });
    };

    Fecha_MsgPopUpPadrao(Tipo_botao){
        this.setState({
            State_MsgPopUpPadrao_Visivel: false,               
        }, () => {
            if ((Tipo_botao === 'ok') || (Tipo_botao === 'sim') || (Tipo_botao === 'timer')){
                if (this.state.State_MsgPopUpPadrao_Acao === 'Teste'){
                    //console.log('Roda a Função Teste');
                };
            };
        });
    };
    
    Abre_LoadPopUpPadrao(mensagem){
        this.setState({
            State_LoadPopUpPadrao_Visivel: true,
            State_LoadPopUpPadrao_Mensagem: mensagem,            
        });
    };

    Fecha_LoadPopUpPadrao(){
        this.setState({
            State_LoadPopUpPadrao_Visivel: false,
            State_LoadPopUpPadrao_Mensagem: '',            
        });
    }; 

    Retorna_Listar_Diversas(){
        return new Promise((resolve) => {
            var objJson = { 
                revenda: true,
                permissao: true,   
            };
    
            this.Abre_LoadPopUpPadrao('Por favor aguarde ...');
            Api.Executa_chamada(
                this.props.navegar, 'Retorna_Listas_Diversas', 0, JSON.stringify(objJson)
            ).then((resposta) => {
                resolve(true);
                this.Fecha_LoadPopUpPadrao();
                if (resposta.status === 1){
                    //console.log('-*-*-*', resposta.dados);
                    this.setState({
                        state_lista_revendas: resposta.dados.revendas,
                        state_lista_permissoes: resposta.dados.permissoes,
                    }, function (){
                        //
                    });
                }else if (resposta.status === 2){
                    this.Abre_MsgPopUpPadrao(3, false, resposta.msg, '', 2000)           
                }
                else{
                    this.Abre_MsgPopUpPadrao(5, false, resposta.msg, '', 5000)
                };           
            });             
        });       
    };     
    
    Pesquisar_Permissao(){
        var objJson = { 
            Pesq_Codigo: this.state.state_permissao_id,
        };

        this.Abre_LoadPopUpPadrao('Por favor aguarde ...');
        Api.Executa_chamada(
            this.props.navegar, 'Permissao_Detalhe', 0, JSON.stringify(objJson)
        ).then((resposta) => {
            this.Fecha_LoadPopUpPadrao();
            if (resposta.status === 1){
                //console.log('----', resposta.dados);
                this.setState({
                    state_permissao_id: resposta.dados.permissao.codigo,                    
                    state_permissao_descricao: resposta.dados.permissao.descricao,
                    state_permissao_cod_revenda: resposta.dados.permissao.cod_revenda, 
                    state_lista_permissoes:resposta.dados.detalhe,
                }, function (){
                    this.Atualiza_Componentes_Permissao();
                });
            }else if (resposta.status === 2){
                this.Abre_MsgPopUpPadrao(3, false, resposta.msg, '', 2000)           
            }
            else{
                this.Abre_MsgPopUpPadrao(5, false, resposta.msg, '', 5000)
            };           
        });        
    };

    Salva_Permissao(){
        if (this.state.state_status_operacao === 'cadastrar'){
            this.Cadastra_permissao();
        }else{
            this.Atualiza_Permissao();
        };
    }

    Cadastra_permissao(){
        //Faz as validações dos campos 
        var objEnvio = [];

        var objPermissao = {
            permissao_id: this.state.state_permissao_id,
            permissao_descricao: this.state.state_permissao_descricao,
            permissao_revenda: this.state.state_permissao_cod_revenda,
        };             

        objEnvio.push(objPermissao);
        objEnvio.push(this.Gera_Array_Permissoes());        

        this.Abre_LoadPopUpPadrao('Por favor aguarde ...');
        Api.Executa_chamada(
            this.props.navegar, 'Permissao_Atu_Cadastra', 0, JSON.stringify(objEnvio)
        ).then((resposta) => {
            this.Fecha_LoadPopUpPadrao();
            if (resposta.status === 1){
                this.setState({
                    state_permissao_id: resposta.dados.permissao_id,
                    state_status_operacao: 'editar',
                }, ()=>{
                    this.Pesquisar_Permissao();
                    this.Abre_MsgPopUpPadrao(1, false, 'Permissão cadastrada com sucesso!', '', 2000);
                });
            }else if (resposta.status === 2){
                this.Abre_MsgPopUpPadrao(3, false, resposta.msg, '', 2000)           
            }
            else{
                this.Abre_MsgPopUpPadrao(5, false, resposta.msg, '', 5000)
            };           
        });        
    }

    Atualiza_Permissao(){
        //Faz as validações dos campos
        var objEnvio = [];

        var objPermissao = { 
            permissao_id: this.state.state_permissao_id,
            permissao_descricao: this.state.state_permissao_descricao,
            permissao_revenda: this.state.state_permissao_cod_revenda,
        };               

        objEnvio.push(objPermissao);
        objEnvio.push(this.Gera_Array_Permissoes());

        this.Abre_LoadPopUpPadrao('Por favor aguarde ...');
        Api.Executa_chamada(
            this.props.navegar,'Permissao_Atu_Cadastra', 0, JSON.stringify(objEnvio)
        ).then((resposta) => {
            this.Fecha_LoadPopUpPadrao();
            if (resposta.status === 1){
                this.setState({
                    state_permissao_id: resposta.dados.permissao_id,
                }, ()=>{
                    this.Pesquisar_Permissao();
                    this.Abre_MsgPopUpPadrao(1, false, 'Permissão Atualizada com sucesso!', '', 2000);
                });
            }else if (resposta.status === 2){
                this.Abre_MsgPopUpPadrao(3, false, resposta.msg, '', 2000)           
            }
            else{
                this.Abre_MsgPopUpPadrao(5, false, resposta.msg, '', 5000)
            };           
        });        
    }

    Gera_Array_Permissoes(){
        var objPermissoes = [];
        var objPerm_Clientes = {};
        var objPerm_Usuarios = {};
        var objPerm_Revendas = {};
        var objPerm_Config_Perm = {};

        objPerm_Clientes = {
            rotina: "cliente",
            ver: this.state.state_permissao_cliente_ver,
            cadastrar: this.state.state_permissao_cliente_cadastrar,
            editar: this.state.state_permissao_cliente_editar,
            excluir: this.state.state_permissao_cliente_excluir,
        };

        objPerm_Usuarios = {
            rotina: "usuario",
            ver: this.state.state_permissao_usuario_ver,
            cadastrar: this.state.state_permissao_usuario_cadastrar,
            editar: this.state.state_permissao_usuario_editar,
            excluir: this.state.state_permissao_usuario_excluir,
        }; 

        objPerm_Revendas = {
            rotina: "revenda",
            ver: this.state.state_permissao_revenda_ver,
            cadastrar: this.state.state_permissao_revenda_cadastrar,
            editar: this.state.state_permissao_revenda_editar,
            excluir: this.state.state_permissao_revenda_excluir,
        };           
        
        objPerm_Config_Perm = {
            rotina: "config_perm",
            ver: this.state.state_permissao_config_perm_ver,
            cadastrar: this.state.state_permissao_config_perm_cadastrar,
            editar: this.state.state_permissao_config_perm_editar,
            excluir: this.state.state_permissao_config_perm_excluir,
        };           

        objPermissoes.push(objPerm_Clientes);
        objPermissoes.push(objPerm_Usuarios);
        objPermissoes.push(objPerm_Revendas);
        objPermissoes.push(objPerm_Config_Perm);

        return objPermissoes;
    }
    
    ChkBloqueadoChange(){
        this.setState({state_cliente_bloqueado_checkbox: ''})    
    };

    Atualiza_Validade_Licensa(data_str){
        if (Global_Valida_Data(data_str)){
            this.setState({state_cliente_validade_licenca: data_str});
        }
    };

    Escolhe_Revenda(e){
        e.preventDefault();
        this.setState({
            state_permissao_cod_revenda: e.target.value
        });
    }    

    render(){
        return(
        <div>
            <MsgPopUpPadrao
                visivel = {this.state.State_MsgPopUpPadrao_Visivel}
                mensagem = {this.state.State_MsgPopUpPadrao_Mensagem}
                tipo = {this.state.State_MsgPopUpPadrao_Tipo}
                mostrar_botoes = {this.state.State_MsgPopUpPadrao_Mostrar_Botoes}
                onclick_btn_ok = {this.Fecha_MsgPopUpPadrao.bind(this, 'ok')}
                onclick_btn_sim = {this.Fecha_MsgPopUpPadrao.bind(this, 'sim')}
                onclick_btn_nao = {this.Fecha_MsgPopUpPadrao.bind(this, 'nao')}
            />
            <PopUpLoadPadrao
                visivel = {this.state.State_LoadPopUpPadrao_Visivel}
                mensagem = {this.state.State_LoadPopUpPadrao_Mensagem}
            />                                 
            <BarraLateral/>
            <div className="GlobalConteinerPrincipal"> 
                <div className="GlobalConteudoPrincipal">

                    <TituloPagina titulo={"Permissões > Cadastro"} /> 
                    <TituloCadastroDetalhe titulo={"Geral"} />
                    <div className={Estilos.ContainerGrid}>
                        <InputPadrao 
                                titulo={'Descrição'}
                                onChange = {valor => this.setState({state_permissao_descricao: valor.target.value})}
                                value = {this.state.state_permissao_descricao}    
                        /> 
                        <ComboBoxPadrao
                            titulo = {'Revenda'}
                            dica = {'Escolha a Revenda'}
                            itens = {this.state.state_lista_revendas}
                            onChange = {this.Escolhe_Revenda}
                            select_value = {this.state.state_permissao_cod_revenda}
                        />                                                                                                                                                                                                                                                                                                                   
                    </div>
                    <div>
                        <TituloCadastroDetalhe titulo={"Permissões"} />
                        <TabelaTipo1
                                estilo = {{minHeight: 'none'}}
                                estilo2 = {{marginTop: '0px'}}
                                titulo = {'Clientes'}
                        >
                            <div className={Estilos.ContainerGrid}>
                                <TooglePadrao
                                            descricao={'Visualizar'}
                                            marcado={this.state.state_permissao_cliente_ver}
                                            onClick={() => this.setState({state_permissao_cliente_ver: (this.state.state_permissao_cliente_ver === 'S') ? 'N' : 'S'})}
                                    />                         
                                <TooglePadrao
                                            descricao={'Cadastrar'}
                                            marcado={this.state.state_permissao_cliente_cadastrar}
                                            onClick={() => this.setState({state_permissao_cliente_cadastrar: (this.state.state_permissao_cliente_cadastrar === 'S') ? 'N' : 'S'})}
                                    /> 
                                <TooglePadrao
                                            descricao={'Editar'}
                                            marcado={this.state.state_permissao_cliente_editar}
                                            onClick={() => this.setState({state_permissao_cliente_editar: (this.state.state_permissao_cliente_editar === 'S') ? 'N' : 'S'})}
                                    />
                                <TooglePadrao
                                            descricao={'Excluir'}
                                            marcado={this.state.state_permissao_cliente_excluir}
                                            onClick={() => this.setState({state_permissao_cliente_excluir: (this.state.state_permissao_cliente_excluir === 'S') ? 'N' : 'S'})}
                                />                                                                        
                            </div> 
                        </TabelaTipo1>                   
                    </div>                         
                    <div>
                        <TabelaTipo1
                            estilo = {{minHeight: 'none'}}
                            estilo2 = {{marginTop: '5px'}}
                            titulo = {'Usuários'}
                        >
                            <div className={Estilos.ContainerGrid}>
                                <TooglePadrao
                                            descricao={'Visualizar'}
                                            marcado={this.state.state_permissao_usuario_ver}
                                            onClick={() => this.setState({state_permissao_usuario_ver: (this.state.state_permissao_usuario_ver === 'S') ? 'N' : 'S'})}
                                    />                         
                                <TooglePadrao
                                            descricao={'Cadastrar'}
                                            marcado={this.state.state_permissao_usuario_cadastrar}
                                            onClick={() => this.setState({state_permissao_usuario_cadastrar: (this.state.state_permissao_usuario_cadastrar === 'S') ? 'N' : 'S'})}
                                    /> 
                                <TooglePadrao
                                            descricao={'Editar'}
                                            marcado={this.state.state_permissao_usuario_editar}
                                            onClick={() => this.setState({state_permissao_usuario_editar: (this.state.state_permissao_usuario_editar === 'S') ? 'N' : 'S'})}
                                    />
                                <TooglePadrao
                                            descricao={'Excluir'}
                                            marcado={this.state.state_permissao_usuario_excluir}
                                            onClick={() => this.setState({state_permissao_usuario_excluir: (this.state.state_permissao_usuario_excluir === 'S') ? 'N' : 'S'})}
                                />                                                                        
                            </div>                             
                        </TabelaTipo1>                   
                    </div> 

                    <div>
                        <TabelaTipo1
                            estilo = {{minHeight: 'none'}}
                            estilo2 = {{marginTop: '5px'}}
                            titulo = {'Revenda'}
                        >
                            <div className={Estilos.ContainerGrid}>
                                <TooglePadrao
                                            descricao={'Visualizar'}
                                            marcado={this.state.state_permissao_revenda_ver}
                                            onClick={() => this.setState({state_permissao_revenda_ver: (this.state.state_permissao_revenda_ver === 'S') ? 'N' : 'S'})}
                                    />                         
                                <TooglePadrao
                                            descricao={'Cadastrar'}
                                            marcado={this.state.state_permissao_revenda_cadastrar}
                                            onClick={() => this.setState({state_permissao_revenda_cadastrar: (this.state.state_permissao_revenda_cadastrar === 'S') ? 'N' : 'S'})}
                                    /> 
                                <TooglePadrao
                                            descricao={'Editar'}
                                            marcado={this.state.state_permissao_revenda_editar}
                                            onClick={() => this.setState({state_permissao_revenda_editar: (this.state.state_permissao_revenda_editar === 'S') ? 'N' : 'S'})}
                                    />
                                <TooglePadrao
                                            descricao={'Excluir'}
                                            marcado={this.state.state_permissao_revenda_excluir}
                                            onClick={() => this.setState({state_permissao_revenda_excluir: (this.state.state_permissao_revenda_excluir === 'S') ? 'N' : 'S'})}
                                />                                                                        
                            </div>                             
                        </TabelaTipo1>                   
                    </div>                     

                    <div>
                        <TabelaTipo1
                            estilo = {{minHeight: 'none'}}
                            estilo2 = {{marginTop: '5px'}}
                            titulo = {'Configurações / Permissões'}
                        >
                            <div className={Estilos.ContainerGrid}>
                                <TooglePadrao
                                            descricao={'Visualizar'}
                                            marcado={this.state.state_permissao_config_perm_ver}
                                            onClick={() => this.setState({state_permissao_config_perm_ver: (this.state.state_permissao_config_perm_ver === 'S') ? 'N' : 'S'})}
                                    />                         
                                <TooglePadrao
                                            descricao={'Cadastrar'}
                                            marcado={this.state.state_permissao_config_perm_cadastrar}
                                            onClick={() => this.setState({state_permissao_config_perm_cadastrar: (this.state.state_permissao_config_perm_cadastrar === 'S') ? 'N' : 'S'})}
                                    /> 
                                <TooglePadrao
                                            descricao={'Editar'}
                                            marcado={this.state.state_permissao_config_perm_editar}
                                            onClick={() => this.setState({state_permissao_config_perm_editar: (this.state.state_permissao_config_perm_editar === 'S') ? 'N' : 'S'})}
                                    />
                                <TooglePadrao
                                            descricao={'Excluir'}
                                            marcado={this.state.state_permissao_config_perm_excluir}
                                            onClick={() => this.setState({state_permissao_config_perm_excluir: (this.state.state_permissao_config_perm_excluir === 'S') ? 'N' : 'S'})}
                                />                                                                        
                            </div>                                                     
                        </TabelaTipo1>                                           
                    </div>                                                                                                                                                                     

                    <div className={Estilos.ConteinerBotoes} >

                        <BotaoPadrao                                           
                            tipo={"voltar"} 
                            descricao={"Voltar"} 
                            onClick={() =>{this.props.navegar(-1)}} 
                        />                                                                       

                        {
                            ((this.state.State_Permissao_Editar || this.state.State_Permissao_cadastrar) && (this.state.state_status_operacao === 'editar' || this.state.state_status_operacao === 'cadastrar')) ?
                            <BotaoPadrao                   
                                tipo={"salvar"} 
                                descricao={"Salvar"} 
                                onClick={() =>{this.Salva_Permissao()}} 
                            /> 
                            : null                            
                        }                         
                    </div>                                                                                                   
                </div>
            </div>
            <Rodape/>
        </div>            
        );
    };
};

export default withRouter(ClientesCadastro);
import React, { Component } from "react";
import EstiloPadrao from "./estilo.module.css";
//import { FaSearch } from "react-icons/fa";
import { CoresGlobal } from "../../../estilos";
import Select from 'react-select';

class InputSelectPadrao extends Component {
    constructor(props){
        super(props)

        //Este state é necessário para o getDerivedStateFromProps
        this.state ={
            state_titulo: '',
            state_dica: '',
            state_estilo: '',
            state_options: '',
            state_onInputChange: undefined,
            state_onKeyDown: undefined,
            state_value_label: '',
            state_value_value: 0,
            state_onChange: undefined,
            state_placeholder: '',
            state_isDisabled: false,


            State_customStyles:{
                container: (provided) => ({
                    ...provided,
                    borderColor: 'hsl(0deg 78.56% 55.56%);',
                    //width: '100%',
                    //height: '2em',
  
                  }),                
                control: (provided) => ({
                  ...provided,
                  //background: 'transparent',
                  //display: 'flex',
                  //flexWrap: 'nowrap',
                  //borderColor: 'hsl(0deg 78.56% 55.56%);',
                  //width: '100%', 
                  //height: '2em',
                  //minHeight: '0.1em',
                  //fontSize: '1em',

                }),
                menu: (provided) => ({
                  ...provided,
                  //background: 'transparent',
                  width: 'none'
                }),
            }, 

        }
    }

    static getDerivedStateFromProps(props, state) {
        return {
            state_titulo: props.titulo,
            state_dica: props.dica,
            state_estilo: props.estilo,
            state_options: props.options,
            state_onInputChange: props.onInputChange,
            state_onKeyDown: props.onKeyDown,
            state_value_label: props.value_label,
            state_value_value: props.value_value,
            state_onChange: props.onChange,
            state_placeholder: props.placeholder,
            state_isDisabled: props.isDisabled
        }
    } 

    render(){
        return(
            <div
                className={EstiloPadrao.ContainerInput} 
                //style={{'width': '100%', background: CoresGlobal.cor_botao_padrao, border: `solid 0.1em ${CoresGlobal.cor_paleta_6}` }} 
                //style={Object.assign({}, {'width': '100%', background: CoresGlobal.cor_botao_padrao, border: `solid 0.1em ${CoresGlobal.cor_paleta_6}` }, this.state.state_estilo)}
                style={Object.assign({}, {'width': '100%', '--cor_paleta_1': CoresGlobal.cor_paleta_1 }, this.state.state_estilo)}
            >
                <div className={EstiloPadrao.ContainerConteudo}>
                {
                    (this.state.state_titulo !== '') ?
                    <div 
                        className={EstiloPadrao.tituloInput} 
                        //style={{backgroundColor: CoresGlobal.cor_botao_padrao}}
                    >
                        {this.state.state_titulo}
                    </div>
                    : null
                }                
                <Select 
                    options={this.state.state_options}
                    onInputChange={this.state.state_onInputChange}
                    onKeyDown={this.state.state_onKeyDown}
                    value={{ label: this.state.state_value_label, value: this.state.state_value_value}}
                    onChange={this.state.state_onChange}
                    placeholder = {this.state.state_placeholder}
                    noOptionsMessage={({inputValue}) => !inputValue && "Nenhuma Informação Disponível"}
                    styles={this.state.State_customStyles} 
                    filterOption={() => true} //Desabilita o filtro para que apareça a lista sem que o valor pesquisado esteja no label
                    isDisabled = {this.state.state_isDisabled}
                    //https://medium.com/@walidkabou/implementing-and-customizing-react-select-a-comprehensive-guide-92d696ea5c35                         
                />
                </div>
            </div>
        );
    }
};

export default InputSelectPadrao;
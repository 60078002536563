//import { FaPlus, FaSearch, FaSave, FaBackward, FaEdit } from "react-icons/fa";
import { CoresGlobal } from "../../../estilos"
import EstiloPadrao from "./estilo.module.css";
import React from "react";

const InputDataPadrao = ({titulo, estilo, disabled,value, onChange}) =>{          
    return(
        <div
        className={EstiloPadrao.ContainerInput} 
        //style={{'width': '100%', background: CoresGlobal.cor_botao_padrao, border: `solid 0.1em ${CoresGlobal.cor_paleta_6}` }} 
        //style={Object.assign({}, {'width': '100%', background: CoresGlobal.cor_paleta_7, border: `solid 0.1em ${CoresGlobal.cor_paleta_6}` }, estilo)}
        style={Object.assign({}, {'width': '100%','--cor_paleta_1': CoresGlobal.cor_paleta_1 }, estilo)}
    >
        <div className={EstiloPadrao.ContainerConteudo}>                  
            {
                (titulo !== '') ?
                <div 
                    className={EstiloPadrao.tituloInput} 
                    //style={{backgroundColor: CoresGlobal.cor_botao_padrao}}
                >
                    {titulo}
                </div>
                : null
            }
            <div style={{display: 'flex'}}>
            <input
                disabled = {disabled}
                className={EstiloPadrao.inputPadrao} 
                type="date"
                onChange={onChange}
                value={value}
                
            >
            </input>                                               
            </div>
        </div>
    </div>        
    );
};

export default InputDataPadrao;
import { Global_Retorna_Permissoes } from "../../Funcoes_Globais";
//import * as RiIcons from "react-icons/ri";
//import * as BsIcons from "react-icons/bs";
import * as FaIcons from "react-icons/fa";
//import * as PiIcons from "react-icons/pi";
//import * as Fa6Icons from "react-icons/fa6";
//import * as TbIcons from "react-icons/tb";
import * as IoIcons from "react-icons/io";

var codigo_interno = 0

export const BarraLateralDados2 = () =>{
    //var lista_permissoes = JSON.parse(Global_Async_Local_Storage.getItem('dados_permissoes')) ? JSON.parse(Global_Async_Local_Storage.getItem('dados_permissoes')) : [];
    var json = [];
    var json_Submenu_Configuracoes = [];
    var json_Submenu_Cora = [];
    var json_Submenu_Revendas = [];
    var objJson = {};

    codigo_interno = codigo_interno + 1
    objJson = {
        key: codigo_interno, 
        titulo: 'Dashboard',
        caminho: '/dashboard',
        //icone: <FaIcons.FaRegArrowAltCircleRight />,   
    };
    json.push(objJson);     

    //Início SubMenu Revendas
    if (Global_Retorna_Permissoes('revenda', 'ver')){
        codigo_interno = codigo_interno + 1
        objJson = {
            key: codigo_interno, 
            titulo: 'Cadastro',
            caminho: '/revenda/consulta',
            //icone: <FaIcons.FaRegArrowAltCircleRight />,    
        };
        json_Submenu_Revendas.push(objJson);        
    }
    if (Global_Retorna_Permissoes('cliente', 'ver')){
        codigo_interno = codigo_interno + 1
        objJson = {
            key: codigo_interno,
            titulo: 'Clientes',
            caminho: '/revenda/clientes/consulta',
            //icone: <FaIcons.FaRegArrowAltCircleRight />,   
        };
        json_Submenu_Revendas.push(objJson);        
    }
    if (Global_Retorna_Permissoes('config_perm', 'ver')){
        codigo_interno = codigo_interno + 1
        objJson = {
            key: codigo_interno, 
            titulo: 'Planos',
            caminho: '/revenda/planos/consulta',
            //icone: <FaIcons.FaRegArrowAltCircleRight/>,   
        };
        json_Submenu_Revendas.push(objJson);
    }      
    if (Global_Retorna_Permissoes('usuario', 'ver')){
        codigo_interno = codigo_interno + 1
        objJson = {
            key: codigo_interno,
            titulo: 'Usuários',
            caminho: '/revenda/usuarios/consulta',
            //icone: <FaIcons.FaRegArrowAltCircleRight/>,   
        };
        json_Submenu_Revendas.push(objJson);        
    }       
    if (json_Submenu_Revendas.length > 0){
        codigo_interno = codigo_interno + 1
        objJson = {
            key: codigo_interno, 
            titulo: 'Revenda',
            caminho: '#',
            //icone: <FaIcons.FaRegArrowAltCircleRight/>,
            iconeFechado: <FaIcons.FaArrowAltCircleDown/>,
            iconeAberto: <FaIcons.FaArrowAltCircleUp/>,
            subMenu: json_Submenu_Revendas,               
        };
        json.push(objJson);         
    }
    //Fim SubMenu das Cora             
    
    //Início SubMenu Cora
    if (Global_Retorna_Permissoes('config_perm', 'ver')){
        codigo_interno = codigo_interno + 1
        objJson = {
            key: codigo_interno, 
            titulo: 'Boletos',
            caminho: '/cora/boletos/consulta',
            icone: <IoIcons.IoIosArrowForward/>,   
        };
        json_Submenu_Cora.push(objJson); 
        
        codigo_interno = codigo_interno + 1
        objJson = {
            key: codigo_interno,
            titulo: 'Configurações',
            caminho: '/cora/config',
            icone: <IoIcons.IoIosArrowForward/>,                
        };
        json_Submenu_Cora.push(objJson);          

    }
    if (json_Submenu_Cora.length > 0){
        codigo_interno = codigo_interno + 1
        objJson = {
            key: codigo_interno, 
            titulo: 'Cora',
            caminho: '#',
            //icone: <FaIcons.FaRegArrowAltCircleRight/>,
            iconeFechado: <FaIcons.FaArrowAltCircleDown/>,
            iconeAberto: <FaIcons.FaArrowAltCircleUp/>,
            subMenu: json_Submenu_Cora,               
        };
        json.push(objJson);         
    }
    //Fim SubMenu das Cora       

    //Início SubMenu das configuraões
    if (Global_Retorna_Permissoes('config_perm', 'ver')){
        codigo_interno = codigo_interno + 1
        objJson = {
            key: codigo_interno,
            titulo: 'Permissões',
            caminho: '/configuracoes/permissoes/consulta',
            icone: <IoIcons.IoIosArrowForward/>,                
        };
        json_Submenu_Configuracoes.push(objJson);
    }    
    
    if (json_Submenu_Configuracoes.length > 0){
        codigo_interno = codigo_interno + 1
        objJson = {
            key: codigo_interno, 
            titulo: 'Configurações',
            caminho: '#',
            //icone: <FaIcons.FaRegArrowAltCircleRight/>,
            iconeFechado: <FaIcons.FaArrowAltCircleDown/>,
            iconeAberto: <FaIcons.FaArrowAltCircleUp/>,
            subMenu: json_Submenu_Configuracoes,               
        };
        json.push(objJson);         
    }
    //Fim SubMenu das configuraões
    return json;
};
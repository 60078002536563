import React, { Component } from "react";
import BarraLateral from "../../../componentes/BarraLateral";
import InputPadrao from "../../../componentes/Inputs/ImputPadrao";
import TituloPagina from "../../../componentes/TituloPagina";
import MsgPopUpPadrao from "../../../componentes/PopUpModal/MsgPopUpPadrao";
import TabelaPadrao from "../../../componentes/Tabelas/TabelaPadrao";
import Paginacao from "../../../componentes/Tabelas/Paginacao";
import Rodape from "../../../componentes/Rodape";
import BotaoPadrao from "../../../componentes/Botoes/BotaoPadrao";
import withRouter from "../../../Rotas/rotasParametros";
import Api from '../../../servicos/Axios';
import PopUpLoadPadrao from '../../../componentes/PopUpLoad/PopUpLoadPadrao';
import { Global_Retorna_Permissoes } from "../../../Funcoes_Globais"

//<Link to={"/clientes/cadastro"}  state={{ testea: "value" }}>Teste com parâmetro</Link>

class RevendasConsulta extends Component{
    constructor(props){
        super(props)

        this.state = {
            State_MsgPopUpPadrao_Visivel: false,
            State_MsgPopUpPadrao_Mensagem: '',
            State_MsgPopUpPadrao_Tipo: 1,
            State_MsgPopUpPadrao_Acao: '',
            State_MsgPopUpPadrao_Mostrar_Botoes: true,

            State_LoadPopUpPadrao_Visivel: false,
            State_LoadPopUpPadrao_Mensagem: '',

            State_Pesq_Valor: '',

            State_Permissao_Editar: Global_Retorna_Permissoes('revenda', 'editar'),
            State_Permissao_ver: Global_Retorna_Permissoes('revenda', 'ver'),
            State_Permissao_cadastrar: Global_Retorna_Permissoes('revenda', 'cadastrar'),            
            /*
            State_lista_clientes: [
                {cliente_id: 1, razao: 'Fernando aragao bonfim'},
                {cliente_id: 2, razao: 'Teste 1 dfa dsfasd fd as'},
                {cliente_id: 3, razao: 'Teste 2 dfas fsdaf asdf ds'},                                                                                                                      
            ],
            */
            State_lista_revendas: [],  
            
            State_Paginacao_Primeira_Consulta: true,
            State_Paginacao_total: 9,
            State_Paginacao_Limit: 9,
            State_Paginacao_Offset: 0,
        }

        this.Editar_Revenda = this.Editar_Revenda.bind(this);
        this.Ver_Revenda = this.Ver_Revenda.bind(this);
        this.Pesquisar_Revenda = this.Pesquisar_Revenda.bind(this);
    };   

    componentDidMount(){
        this.Pesquisar_Revenda();
    }

    Editar_Revenda(revenda_id){
        this.props.navegar("/revenda/cadastro", {state: { revenda_id: revenda_id, status_operacao: 'editar' } });
    } 
    
    Ver_Revenda(revenda_id){
        this.props.navegar("/revenda/cadastro", {state: { revenda_id: revenda_id, status_operacao: 'ver' } });
    }       

    Adicionar_Revenda(){
        this.props.navegar("/revenda/cadastro", {state: { revenda_id: 0, status_operacao: 'cadastrar' } });
    }

    Abre_MsgPopUpPadrao(Tipo = 1, Mostrar_botoes = true, Mensagem = '', Acao_Botao = '', Tempo_fechar = 0){
        this.setState({
            State_MsgPopUpPadrao_Visivel: true,
            State_MsgPopUpPadrao_Tipo: Tipo,
            State_MsgPopUpPadrao_Mensagem: Mensagem,
            State_MsgPopUpPadrao_Acao: Acao_Botao, 
            State_MsgPopUpPadrao_Mostrar_Botoes: Mostrar_botoes,  
        }, () => {
            if (Tempo_fechar > 0){
                setTimeout(
                    this.Fecha_MsgPopUpPadrao.bind(this, 'timer'),
                    Tempo_fechar
                );                
            };
        });
    };

    Fecha_MsgPopUpPadrao(Tipo_botao){
        this.setState({
            State_MsgPopUpPadrao_Visivel: false,               
        }, () => {
            if ((Tipo_botao === 'ok') || (Tipo_botao === 'sim') || (Tipo_botao === 'timer')){
                if (this.state.State_MsgPopUpPadrao_Acao === 'Teste'){
                    //console.log('Roda a Função Teste');
                };
            };
        });
    };
    
    Abre_LoadPopUpPadrao(mensagem){
        this.setState({
            State_LoadPopUpPadrao_Visivel: true,
            State_LoadPopUpPadrao_Mensagem: mensagem,            
        });
    };

    Fecha_LoadPopUpPadrao(){
        this.setState({
            State_LoadPopUpPadrao_Visivel: false,
            State_LoadPopUpPadrao_Mensagem: '',            
        });
    };

    Pesquisar_Revenda(Offset = 0){
        /*
            O State Pesq_Pagi_Primeira_Consulta Precisa ser alterado no onchange do imput da consulta
            Se o retorno for  dois, jogar o state State_Paginacao_total: 9, State_Paginacao_Limit: 9, State_Paginacao_Offset: 0
        */
        this.setState({
            State_Paginacao_Offset: Offset,
            State_lista_revendas: []
        });

        var objJson = { 
            Pesq_Valor: this.state.State_Pesq_Valor,
            Pesq_Pagi_Offset: Offset,
            Pesq_Pagi_Limit: this.state.State_Paginacao_Limit,
            Pesq_Pagi_Primeira_Consulta: this.state.State_Paginacao_Primeira_Consulta, 
        }

        this.Abre_LoadPopUpPadrao('Por favor aguarde ...');
        Api.Executa_chamada(
            this.props.navegar, 'Revenda_Consulta', 0, JSON.stringify(objJson), this.props.navegar
        ).then((resposta) => {
            this.Fecha_LoadPopUpPadrao();
            if (resposta.status === 1){
                this.setState({
                    State_lista_revendas: resposta.dados.revenda,
                }, function(){
                    if(this.state.State_Paginacao_Primeira_Consulta){
                        this.setState({
                            State_Paginacao_total: resposta.dados.qtd_paginas_calculadas, 
                            State_Paginacao_Primeira_Consulta: false,                            
                        });
                    };
                    //console.log('Total>', this.state.State_Paginacao_total);
                });
            }else if (resposta.status === 2){
                //this.Abre_MsgPopUpPadrao(3, false, resposta.msg, '', 2000)
                this.setState({
                    State_Paginacao_total: 9,
                    State_Paginacao_Limit: 9,
                    State_Paginacao_Offset: 1,
                });                          
            }
            else{
                this.Abre_MsgPopUpPadrao(5, false, resposta.msg, '', 5000)
            };           
        });
    }
    
    ItemPaginacao_Click(codigo){
        this.Pesquisar_Revenda(codigo);
    }

    render(){
        return(
        <div>    
            <MsgPopUpPadrao
                visivel = {this.state.State_MsgPopUpPadrao_Visivel}
                mensagem = {this.state.State_MsgPopUpPadrao_Mensagem}
                tipo = {this.state.State_MsgPopUpPadrao_Tipo}
                mostrar_botoes = {this.state.State_MsgPopUpPadrao_Mostrar_Botoes}
                onclick_btn_ok = {this.Fecha_MsgPopUpPadrao.bind(this, 'ok')}
                onclick_btn_sim = {this.Fecha_MsgPopUpPadrao.bind(this, 'sim')}
                onclick_btn_nao = {this.Fecha_MsgPopUpPadrao.bind(this, 'nao')}
            />
            <PopUpLoadPadrao
                visivel = {this.state.State_LoadPopUpPadrao_Visivel}
                mensagem = {this.state.State_LoadPopUpPadrao_Mensagem}
            />
            <BarraLateral/>
            <div className="GlobalConteinerPrincipal"> 
                <div className="GlobalConteudoPrincipal">

                    <TituloPagina titulo={"Revendas"} /> 
                    {
                        this.state.State_Permissao_cadastrar ?
                        <BotaoPadrao
                            tipo={"adicionar"}
                            descricao={"Novo"}
                            onClick={() =>{this.Adicionar_Revenda()}}
                        />
                        : null                            
                    }                                    
                    <InputPadrao 
                        //estilo={{ width:'100%', "margin-left": '10px'}}
                        //titulo={'Pesquisar'}
                        dica = {'Razão ou Cnpj'}
                        value = {this.state.State_Pesq_Valor}
                        tipo = {'pesquisa'}
                        onClick={() =>{this.Pesquisar_Revenda()}}                    
                        onChange = {valor => this.setState({State_Pesq_Valor: valor.target.value, State_Paginacao_Primeira_Consulta:true})}
                        onKeyUp={event => {if (event.key === 'Enter') {this.Pesquisar_Revenda()}}}
                        //autoFocus = {true}
                    />
                    <TabelaPadrao
                        estilo = {{marginTop: '0px'}}
                        //titulo={''}
                        mensagemItemVazio = {'Nenhuma Revenda Encontrada'}
                        Itens = {this.state.State_lista_revendas}
                        motrarBtnEditar={this.state.State_Permissao_Editar}
                        motrarBtnVisualizar={this.state.State_Permissao_ver}
                        motrarBtnExcluir = {false}
                        clickEditar={this.Editar_Revenda}
                        clickVisualizar={this.Ver_Revenda}
                    />   
                    <Paginacao 
                        limit={this.state.State_Paginacao_Limit}
                        total={this.state.State_Paginacao_total}
                        offset={this.state.State_Paginacao_Offset}
                        setOffset={this.Pesquisar_Revenda}                        
                    />

                </div>
            </div>
            <Rodape/>
        </div>            
        );
    };
};

export default withRouter(RevendasConsulta);
//import ItemTabelaPadrao from "../TabelaPadrao/ItemTabelaPadrao";
import { CoresGlobal } from "../../../estilos";
import Estilo from "./estilo.module.css";
import React from "react";
//import { MdHeight } from "react-icons/md";

//const TabelaTipo1 = ({id, titulo, Itens, onClick}) =>{
function TabelaTipo1 (props,{estilo, estilo2, transbordarY, titulo}) {        
    return(
        <div 
            style={Object.assign({},{width: '100%', border: `solid 2px ${CoresGlobal.cor_paleta_6}`, marginTop: '0em'}, props.estilo2)}
        >
            {
                (props.titulo) ? 
                <div className={Estilo.TabelaTitulo} style={{backgroundColor: CoresGlobal.cor_botao_padrao}}>
                    {props.titulo}
                </div>
                :null                
            }                
            <div 
                style={
                    //Object.assign({},{height: '20em', overflowY: 'scroll',}, estilo)
                    Object.assign({},{ padding: '5px', minHeight: '20em', overflowY: props.transbordarY ? 'scroll' : 'none',}, props.estilo)
                }
            >
                <div>
                    {props.children}                                                
                </div>
            </div>
        </div>
    );
};

export default TabelaTipo1;